import { transliterate } from "transliteration"

export const renderPrice = price => {
  return price + " бел. руб."
}

export const renderDeposit = (price, deposit) => {
  return `${deposit} бел. руб. (${Math.round(
    (parseFloat(deposit) / parseFloat(price)) * 100
  )}%)`
}

export const formatInfrastructure = items => {
  return items
    .map(item => item.Name)
    .filter(item => item)
    .join(", ")
}

export const formatDate = input => {
  const date = new Date(input)
  return date.toLocaleDateString("ru")
}

export const transliterateTextToUrl = text => {
  return transliterate(text.toLowerCase().replace(" ", "-"))
}
