import React from "react"
import SEO from "../components/seo"
import AuctionCard from "../components/AuctionCard"
import Layout from "../components/layout"

const RegionTemplate = ({ pageContext }) => {
  const { auctions, region } = pageContext
  return (
    <Layout>
      <SEO
        title={`Аукционы земельных участков - ${region}`}
        description={`Аукционы земельных участков - ${region}. Купить участок, построить дом.`}
      />
      <h2 className="mt-4">Аукционы земельных участков - {region}</h2>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
        {auctions.map(item => (
          <AuctionCard key={item.id} item={item} />
        ))}
      </div>
    </Layout>
  )
}

export default RegionTemplate
