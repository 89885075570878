import React from "react"
import { Link } from "gatsby"
import { IconContext } from "react-icons"
import { FaMapMarker } from "react-icons/fa"
import { formatDate, transliterateTextToUrl } from "../utils/helpers"

const AuctionCard = ({ item }) => (
  <div className="col mb-4">
    <div className="card my-2">
      <div className="card-body">
        <h4 className="card-title text-muted">
          <Link to={`/auction/${item.id}`}>{item.Title}</Link>
        </h4>
      </div>
      <div className="card-footer">
        <small className="text-muted pr-1">{formatDate(item.Date)}</small>
        <IconContext.Provider value={{ color: "#333" }}>
          <FaMapMarker />
        </IconContext.Provider>
        <small className="text-muted pl-1">
          {item.Region && (
            <Link
              className="text-muted"
              to={`/region/` + transliterateTextToUrl(item.Region.Name)}
            >
              {item.Region.Name}
            </Link>
          )}
        </small>
      </div>
    </div>
  </div>
)

export default AuctionCard
